import { computed, onMounted, onUnmounted, ref } from "vue"


export function formatPhone(phone) {
    phone = phone.replace(/[^0-9]/, "");
    var length = phone.length;

    switch(length){
        case 11 :
            return phone.replace(/([0-9]{3})([0-9]{4})([0-9]{4})/, "$1-$2-$3");
        case 10:
            return phone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, "$1-$2-$3");
        case 9:
            return phone.replace(/([0-9]{2})([0-9]{3})([0-9]{4})/, "$1-$2-$3");
    }
    return phone;
}
export function numberFormat(value) {
    return Number(Number(value).toFixed(0)).toLocaleString('ko-KR');
}
export const useBreakpoints = () => {
    let windowWidth = ref(window.innerWidth)

    const onWidthChange = () => windowWidth.value = window.innerWidth
    onMounted(() => window.addEventListener('resize', onWidthChange))
    onUnmounted(() => window.removeEventListener('resize', onWidthChange))

    const type = computed(() => {
        if (windowWidth.value < 550) return 'xs'
        if (windowWidth.value >= 550 && windowWidth.value < 1200) return 'md'
        if (windowWidth.value >= 1200) return 'lg'
        return null; // This is an unreachable line, simply to keep eslint happy.
    })

    const width = computed(() => windowWidth.value)

    return { width, type }
}

export function toHHMM(value) {
    var sec_num = parseInt(value, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return ((hours === '00')?'':hours+':')+minutes+'분';
}
